import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col, Tabs, Tab, Badge, Tooltip, OverlayTrigger, Container} from 'react-bootstrap'
import Promise from 'bluebird'
import {List as Loading} from 'react-content-loader'
import {includes, filter, reject, map, get, isEmpty, find, first, last, findIndex} from 'lodash'
import {appropTestStatuses, tradingPositionStatuses, socketEvents,
  alertStatuses, activityLogTypes, depositVendors, withdrawalTypes, transferTypes, tradingStatuses,
  suspendedTradingStatusReasons, dueDiligenceStatuses, withdrawalStatuses, clientNoticeTypes, suitabilityTestStatuses} from '@bdswiss/common-enums'
import WithdrawalEditor from '../components/WithdrawalEditor'
import PureComponent from '../PureComponent'
import {getIn} from '../stateUtils'
import {getFullName, isNumeric, isConverted} from '../useful'
import {clientTabs} from '../enums'
import {registerClientConnectionHandler} from '../utils/socket'
import {joinRoom, leaveRoom, compareAccounts} from '../utils/general'
import {compose, uiMount, predispatch, provideProps, mountDataProviders} from '../decorators'
import {clientProvider, initActivityLog} from './providers'
import GeneralInfo from './GeneralInfo'
import PersonalDetails from './PersonalDetails'
import Accounts from './accounts/'
import Alerts from './Alerts'
import ClientEditor from './ClientEditor'
import Deposits from './Deposits'
import Withdrawals from './Withdrawals'
import Documents from './Documents'
import Positions from './Positions'
import Events from './Events'
import CallLog from './CallLog'
import CreateAccount from './CreateAccount'
import CreateDeposit from './CreateDeposit'
import DocumentEditor from './DocumentEditor'
import ChangeCompanyModal from './ChangeCompanyModal'
import ResetPassword from './ResetPassword'
import SecurityCode from './SecurityCode'
import DepositDetails from './DepositDetails'
import AppropTestDetails from './AppropTestDetails'
import ProfileChangeDetails from './ProfileChangeDetails'
import {fieldCategories, personalDetailsFields} from './personalDetailsFields'
import EditAccount from './EditAccount'
import UpdateWalkthrough from './UpdateWalkthrough'
import UpdatePotential from './UpdatePotential'
import EditPromotingCountries from './EditPromotingCountries'
import UpdateEconomicProfile from './UpdateEconomicProfile'
import CreateTransfer from './CreateTransfer'
import {canQueryAlerts, canQueryAffiliateManagerClients} from '@bdswiss/common-permissions'
import Acknowledgements from './Acknowledgements'
import Notifications from './Notifications'
import SendInvitation from './SendInvitation'
import {parseJSON, pageSizeEnum} from '../common/utils'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import Communications from './communications'
import SMS from './SMS'
import PushNotifications from './PushNotifications'
import Emails from './Emails'
import CreateVpsSubscription from './CreateVpsSubscription'
import GenerateTaxDocument from './GenerateTaxDocument'
import DueDiligenceDetails from './DueDiligenceDetails'
import RestrictedPersonalDetails from './RestrictedPersonalDetails'
import LinkClient from './LinkClient'
import AppendixForm from './AppendixForm'
import SuitabilityTestDetails from './SuitabilityTestDetails'
import DueDiligenceForm from './DueDiligenceForm'
import Referrals from './Referrals'
import VPS from './VPS'
import SendEmailTemplateModal from './SendEmailTemplateModal'
import style from './client.module.scss'
import classNames from 'classnames'
import AttachToAffiliate from './attachtoaffiliate/AttachToAffiliate'
import {ResetTotp} from './ResetTotp'

export const uiStatePath = ['clientUi']
const EventsMounted = uiMount(() => ['clientUi', 'Events'])(Events)

const tabTooltip = (<Tooltip id="tabTitleTooltip">Pending</Tooltip>)
const tabTooltipHeldIncomplete = (<Tooltip id="tabTitleTooltip">Pending/Held/Incomplete</Tooltip>)

export const sidebarsEnum = {
  default: 0,
  newWidthdrawal: 1,
  showWidthdrawal: 2,
  newDeposit: 3,
  newPassword: 4,
  newDocument: 5,
  showDocument: 6,
  editClient: 7,
  accountChecks: 8,
  newAccount: 9,
  showDeposit: 10,
  showAppropTest: 11,
  showProfileChange: 12,
  editAccount: 13,
  updateWalkthroughs: 14,
  createTransfer: 15,
  changeCompany: 16,
  securityCode: 17,
  sendInvitation: 18,
  updateEconomicProfile: 19,
  updatePotentials: 20,
  generateTaxDocument: 21,
  showDueDiligence: 22,
  linkClient: 23,
  addAppendix:24,
  editAppendix:25,
  showSuitabilityTest: 26,
  editDueDiligence: 27,
  editPromotingCountries: 28,
  createVpsSubscription: 29,
  loginVerificationDetails: 30,
  sendEmailTemplate: 31,
  newClientWithdrawal: 32,
  newClientDeposit: 33,
  createTransferClient: 34,
  attachToAffiliate: 35,
  resetTotp: 36,
}

function buildSidebarFromParams(tab, sidebarArg) {
  if (tab === clientTabs.withdrawals.key && sidebarArg != null) {
    if (isNumeric(sidebarArg)) {
      return {
        sidebar: sidebarsEnum.showWidthdrawal,
        sidebarData: {showWithdrawalId: Number(sidebarArg)},
      }
    }

    if (sidebarArg === 'new') {
      return {
        sidebar: sidebarsEnum.newWidthdrawal,
        sidebarData: {},
      }
    }

    console.warn('Unexpected client withdrawal tab argument ', sidebarArg) // eslint-disable-line no-console
  }

  if (tab === clientTabs.deposits.key && sidebarArg != null) {
    if (isNumeric(sidebarArg)) {
      return {
        sidebar: sidebarsEnum.showDeposit,
        sidebarData: {showDepositId: Number(sidebarArg)},
      }
    }
    if (sidebarArg.startsWith('bo-') && isNumeric(sidebarArg.substring(3))) {
      return {
        sidebar: sidebarsEnum.showDeposit,
        sidebarData: {showBonusOfferId: Number(sidebarArg.substring(3))},
      }
    }
    if (sidebarArg === 'new') {
      return {
        sidebar: sidebarsEnum.newDeposit,
        sidebarData: {},
      }
    }

    console.warn('Unexpected client deposit tab argument ', sidebarArg) // eslint-disable-line no-console
  }

  if (tab === clientTabs.documents.key && sidebarArg != null) {
    if (isNumeric(sidebarArg)) {
      return {
        sidebar: sidebarsEnum.showDocument,
        sidebarData: {showDocumentId: Number(sidebarArg)},
      }
    }
    if (sidebarArg.startsWith('apt-')) {
      return {
        sidebar: sidebarsEnum.showAppropTest,
        sidebarData: {showDocumentId: sidebarArg},
      }
    }
    if (sidebarArg.startsWith('pc-')) {
      return {
        sidebar: sidebarsEnum.showProfileChange,
        sidebarData: {showDocumentId: sidebarArg},
      }
    }
    if (sidebarArg === 'new') {
      return {
        sidebar: sidebarsEnum.newDocument,
        sidebarData: {},
      }
    }
    if (sidebarArg.startsWith('edd-')) {
      return {
        sidebar: sidebarsEnum.showDueDiligence,
        sidebarData: {showDocumentId: sidebarArg},
      }
    }
    if (sidebarArg.startsWith('editDueDiligence-')) {
      return {
        sidebar: sidebarsEnum.editDueDiligence,
        sidebarData: {showDocumentId: sidebarArg},
      }
    }
    if (sidebarArg.startsWith('sui-')) {
      return {
        sidebar: sidebarsEnum.showSuitabilityTest,
        sidebarData: {showDocumentId: sidebarArg},
      }
    }
    console.warn('Unexpected client document tab argument ', sidebarArg) // eslint-disable-line no-console
  }

  if (tab === clientTabs.accounts.key && sidebarArg != null) {
    if (sidebarArg === 'new') {
      return {
        sidebar: sidebarsEnum.newAccount,
        sidebarData: {},
      }
    }

    if (isNumeric(sidebarArg)) {
      return {
        sidebar: sidebarsEnum.editAccount,
        sidebarData: {showAccountId: Number(sidebarArg)},
      }
    }

    if (sidebarArg === clientTabs.accounts.sidebars.createTransfer.name) {
      return {
        sidebar: sidebarsEnum.createTransfer,
        sidebarData: {},
      }
    }

    console.warn('Unexpected client account tab argument ', sidebarArg) // eslint-disable-line no-console
  }

  if (tab === clientTabs.vps.key && sidebarArg != null) {
    if (sidebarArg === 'createVpsSubscription') {
      return {
        sidebar: sidebarsEnum.createVpsSubscription,
        sidebarData: {},
      }
    }
  }
  if (tab === clientTabs.acknowledgements.key && sidebarArg != null) {
    if (sidebarArg === 'addAppendix') {
      return {
        sidebar: sidebarsEnum.addAppendix,
        sidebarData: {},
      }
    }
    if (sidebarArg === 'editAppendix') {
      return {
        sidebar: sidebarsEnum.editAppendix,
        sidebarData: {},
      }
    }

    if (isNumeric(sidebarArg)) {
      return {
        sidebar: sidebarsEnum.editAccount,
        sidebarData: {showAccountId: Number(sidebarArg)},
      }
    }

    console.warn('Unexpected client account tab argument ', sidebarArg) // eslint-disable-line no-console
  }

  if (tab === clientTabs.details.key && sidebarArg != null) {
    if (sidebarArg === clientTabs.details.sidebars.changeClientCompany.name) {
      return {
        sidebar: sidebarsEnum.changeCompany,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.attachToAffiliate.name) {
      return {
        sidebar: sidebarsEnum.attachToAffiliate,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.securityCode.name) {
      return {
        sidebar: sidebarsEnum.securityCode,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.resetPassword.name) {
      return {
        sidebar: sidebarsEnum.newPassword,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.resetTotp.name) {
      return {
        sidebar: sidebarsEnum.resetTotp,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.edit.name) {
      return {
        sidebar: sidebarsEnum.editClient,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.accountChecks.name) {
      return {
        sidebar: sidebarsEnum.accountChecks,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.updateWalkthroughs.name) {
      return {
        sidebar: sidebarsEnum.updateWalkthroughs,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.updateEconomicProfile.name) {
      return {
        sidebar: sidebarsEnum.updateEconomicProfile,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.sendInvitation.name) {
      return {
        sidebar: sidebarsEnum.sendInvitation,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.updatePotentials.name) {
      return {
        sidebar: sidebarsEnum.updatePotentials,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.editPromotingCountries.name) {
      return {
        sidebar: sidebarsEnum.editPromotingCountries,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.generateTaxDocument.name) {
      return {
        sidebar: sidebarsEnum.generateTaxDocument,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.linkClient.name) {
      return {
        sidebar: sidebarsEnum.linkClient,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.loginVerificationDetails.name) {
      return {
        sidebar: sidebarsEnum.loginVerificationDetails,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.sendEmailTemplate.name) {
      return {
        sidebar: sidebarsEnum.sendEmailTemplate,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.newClientWithdrawal.name) {
      return {
        sidebar: sidebarsEnum.newClientWithdrawal,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.newClientDeposit.name) {
      return {
        sidebar: sidebarsEnum.newClientDeposit,
        sidebarData: {},
      }
    }

    if (sidebarArg === clientTabs.details.sidebars.createTransferClient.name) {
      return {
        sidebar: sidebarsEnum.createTransferClient,
        sidebarData: {},
      }
    }

    console.warn('Unexpected client details tab argument ', sidebarArg) // eslint-disable-line no-console
  }

  return {
    sidebar: sidebarsEnum.default,
    sidebarData: null,
  }
}

class Client extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    clientProvider: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    toggleAppointmentEditor: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setState({})
  }

  componentWillReceiveProps(nextProps) {
    const {tab: oldTab, sidebarArg: oldSidebarArg} = this.props
    const {tab: newTab, sidebarArg: newSidebarArg} = nextProps

    // do nothing if sidebar related url params stayed the same - correct sidebar is on state
    if (oldTab === newTab && oldSidebarArg === newSidebarArg) {
      return
    }

    const sidebar = buildSidebarFromParams(newTab, newSidebarArg)
    //small optimization - if the sidebar needs to turn off and is already turned off, do nothing
    if (sidebar.sidebar === sidebarsEnum.default && nextProps.sidebar === sidebarsEnum.default) {
      return
    }

    this.switchSidebar(sidebar.sidebar, sidebar.sidebarData)
  }

  componentDidMount() {
    const {clientId, client:{personalDetails}} = this.props
    this.context.clientProvider.subProviders.activityLogs.fetch()
    registerClientConnectionHandler()
    joinRoom(`client-${clientId}`)

    if (window.ioSocket) {
      window.ioSocket.on(socketEvents.refresh.value, ({id}) => {
        const {clientId} = this.props
        if (id === clientId) {
          this.fetchProvider()
        }
      })
    }
    if (personalDetails.tradingStatus === tradingStatuses.suspended.value &&
        personalDetails.tradingStatusReasonCode === suspendedTradingStatusReasons.chargebackCase.value) {
      window.alert(`No further action should be taken on this account until the chargeback case is resolved.
      \nDo not re-activate the account!`)
    }
    if (personalDetails.hasOpenComplaint) {
      window.alert('Client has an open complaint. Please resolve as soon as possible')
    }
    if (personalDetails.hasPreSurvey) {
      window.alert('Pre-Survey sent to client')
    }
    const {tab, sidebarArg} = this.props
    const sidebar = buildSidebarFromParams(tab, sidebarArg)
    if (sidebar.sidebar === sidebarsEnum.showDeposit) {
      this.props.actions.client.fetchDepositActivityLogs(sidebar.sidebarData.showDepositId).then((res) =>
        this.setState({depositActivityLogs: initActivityLog(get(res, 'deposit.activityLogs') || [])}))
    }

    if (sidebar.sidebar === sidebarsEnum.showWidthdrawal) {
      this.props.actions.client.fetchWithdrawalActivityLogs(sidebar.sidebarData.showWithdrawalId).then((res) =>
        this.setState({withdrawalActivityLogs: initActivityLog(get(res, 'withdrawal.activityLogs') || [])}))
    }

    if (sidebar.sidebar === sidebarsEnum.showDocument) {
      this.props.actions.client.fetchDocumentActivityLogs(sidebar.sidebarData.showDocumentId).then((res) =>
        this.setState({documentActivityLogs: initActivityLog(get(res, 'document.activityLogs') || [])}))
    }

    this.switchSidebar(sidebar.sidebar, sidebar.sidebarData)
  }

  componentWillUnmount() {
    const {clientId} = this.props
    leaveRoom(`client-${clientId}`)
  }

  fetchProvider() {
    this.context.clientProvider.fetch()
  }

  switchSidebar = (sidebar, data = null) => {
    this.props.uiDispatch(
      'Change sidebar to ' + sidebar,
      (state) => ({...state, sidebar: sidebar, sidebarData: data})
    )
  }

  closeSidebar = () => {
    this.context.router.push(`/clients/${this.props.clientId}/${this.props.tab}`)
  }

  countPendingAlerts() {
    const alerts = this.props.client.alerts || []
    return alerts.filter(({status}) => [alertStatuses.postponed.key,
      alertStatuses.active.key].includes(status)).length
  }

  fetchDepositActivityLogs = (id) => (
    this.props.actions.client.fetchDepositActivityLogs(id).then((res) =>
      this.setState({depositActivityLogs: initActivityLog(get(res, 'deposit.activityLogs') || [])})
    )
  )

  fetchWithdrawalActivityLogs = (id) => (
    this.props.actions.client.fetchWithdrawalActivityLogs(id).then((res) =>
      this.setState({withdrawalActivityLogs: initActivityLog(get(res, 'withdrawal.activityLogs') || [])})
    )
  )

  fetchDocumentActivityLogs = (id) => (
    this.props.actions.client.fetchDocumentActivityLogs(id).then((res) =>
      this.setState({documentActivityLogs: initActivityLog(get(res, 'document.activityLogs') || [])})
    )
  )

  revealPhone(clientId, profileChangeId) {
    this.props.actions.client.revealPhone(clientId, profileChangeId).then((phones) => {
      const currentPhone = get(this.props, 'client.personalDetails.phone', '')
      const currentSecPhone = get(this.props, 'client.personalDetails.secondaryPhones', '')
      const phone = get(phones, 'phone', currentPhone)
      const secondaryPhones = get(phones, 'secondaryPhones', currentSecPhone)
      if (phone || !isEmpty(secondaryPhones)) {
        this.props.uiDispatch('Dispatching Reveal Phone',
          (state) => ({
            ...state,
            sidebarData: {
              ...state.sidebarData,
              phones: {
                phone,
                ...secondaryPhones,
              },
              phoneRevealed: true,
            },
          }))
      }
    })
  }

  renderDefaultSidebar() {
    const {client, clientId, viewer, state: {config: {callRecordingsUri}}, match, filterDispatch, filterState,
      userDepartmentFilter, emailsPage, fetchingMoreEmails,
    } = this.props
    const {sideTab = viewer.permissions.indexOf('alert_read') > -1 &&
          this.props.client.alerts.length > 0 ? 'alerts' : 'activity', commsActiveTab = 'callLogs'} = this.state
    const salesAgentId = getIn(this.props, ['client', 'generalInfo', 'salesAgent', 'id'], {any: null})
    const supportAgentId = getIn(this.props, ['client', 'generalInfo', 'supportAgent', 'id'], {any: null})
    const externalAgentId = getIn(this.props, ['client', 'generalInfo', 'externalAgent', 'id'], {any: null})
    const key = `events ${clientId}`
    const alertsTitle = (<span>
      <FontAwesomeIcon icon="exclamation-triangle" /> Alerts&nbsp;
      {this.countPendingAlerts() > 0 && (<Badge id="alert-badge" variant="danger" pill>{this.countPendingAlerts()}</Badge>)}
    </span>)
    const notificationsKeys = map(filter(activityLogTypes, {isNotification: true}), 'key')
    const activityLogsKeys = map(reject(activityLogTypes, {isNotification: true}), 'key')
    const notifications = filter(client.activityLogs, (activityLogs) =>
      includes(notificationsKeys, activityLogs.type))
    const activityLogs = filter(client.activityLogs, (activityLogs) =>
      includes(activityLogsKeys, activityLogs.type))

    return (
      <Tabs
        id="t-client-activity-tabs"
        activeKey={sideTab}
        onSelect={
          (sideTab) => this.setState({sideTab})
        }
      >
        <Tab
          eventKey="activity"
          title={(<span><FontAwesomeIcon icon="list" /> Activity</span>)}
        >

          <Tabs
            // activeKey={activityActiveTab}
            onSelect={(tab) => this.setState({activityActiveTab: tab})}
          >
            <Tab
              eventKey="activityLog"
              title={(<span><FontAwesomeIcon icon="list" /> Activity Log</span>)}
            >
              <EventsMounted
                key={key}
                clientId={clientId}
                salesAgentId={salesAgentId}
                supportAgentId={supportAgentId}
                externalAgentId={externalAgentId}
                appointments={client.appointments}
                activityLogs={activityLogs}
                activityLogsCount={client.activityLogsCount}
                showAppointmentEditor={this.showAppointmentEditor}
                fetchMore={() => {
                  this.context.clientProvider.subProviders.activityLogs.fetch()
                }}
                sideTab={sideTab}
              />
            </Tab>

            {/* <Tab
              eventKey="tickets"
              title={(<span><FontAwesomeIcon icon="list" /> Tickets</span>)}
            >
              <TicketListingV clientId={clientId}/>
            </Tab> */}
          </Tabs>
        </Tab>
        {!canQueryAffiliateManagerClients(viewer) && <Tab
          eventKey="communications"
          title={(<span title="Communications"><FontAwesomeIcon icon="comments-o" /> Comms</span>)}
          className="sidebar-col"
        >
          <Tabs
            activeKey={commsActiveTab}
            onSelect={(tab) => this.setState({commsActiveTab: tab})}
          >
            <Tab
              eventKey="timeline"
              title={(<span><FontAwesomeIcon icon="clock" /> Timeline</span>)}
            >
              <Communications
                isActive={commsActiveTab === 'timeline'}
                callRecordingsUri={callRecordingsUri}
                match={match}
              />
            </Tab>
            <Tab
              eventKey="sms"
              title={(<span><FontAwesomeIcon icon="comments" /> SMS</span>)}
            >
              <SMS isActive={commsActiveTab === 'sms'} />
            </Tab>
            <Tab
              eventKey="push-notifications"
              title={(<span><FontAwesomeIcon icon="bell" /> Push</span>)}
            >
              <PushNotifications isActive={commsActiveTab === 'push-notifications'} match={match} />
            </Tab>
            <Tab
              eventKey="callLogs"
              title={(<span><FontAwesomeIcon icon="phone" /> Calls</span>)}
            >
              <CallLog
                clientId={clientId}
                calls={client.calls}
                callRecordingsUri={callRecordingsUri}
                lastEffectiveCall={client.personalDetails.lastEffectiveCall}
                isActive={commsActiveTab === 'callLogs'}
                actions={this.props.actions.callLog}
                viewer={viewer}
              />
            </Tab>
            <Tab
              eventKey="emails"
              title={(<span><FontAwesomeIcon icon="envelope" /> Emails</span>)}
            >
              <Emails
                filterDispatch={filterDispatch}
                filterState={filterState}
                isActive={commsActiveTab === 'emails'}
                match={match}
                userDepartmentFilter={userDepartmentFilter}
                emailsPage={emailsPage}
                fetchingMoreEmails={fetchingMoreEmails}
              />
            </Tab>
          </Tabs>

        </Tab>}
        {canQueryAlerts(viewer) &&
          <Tab
            eventKey="alerts"
            title={alertsTitle}
          >
            <Alerts alerts={client.alerts} />
          </Tab>
        }
        <Tab
          eventKey="notifications"
          title={(<span><FontAwesomeIcon icon="bullhorn" /> Notifications</span>)}
        >
          <Notifications
            clientId={clientId}
            notifications={notifications}
            sideTab={sideTab}
          />
        </Tab>
      </Tabs>
    )
  }

  renderSidebar() {
    const {client, clientId, sidebar, sidebarData = {}, actions, state: {config, agents}, viewer,
      client: {paymentMethods, pendingNotices, acknowledgedNotices}, match} = this.props
    let partnersNotices = filter(pendingNotices, (notice) => notice.type === clientNoticeTypes.partners.value)
    const checkAcknowledged = filter(acknowledgedNotices, (o) => o.notice.type === clientNoticeTypes.partners.value)
    if (isEmpty(partnersNotices)) {
      if (!isEmpty(checkAcknowledged)) {
        partnersNotices = [get(last(checkAcknowledged), 'notice')]
      }
    }
    const {deposits, withdrawals, documents} = client
    const onClose = this.closeSidebar
    switch (sidebar) {
      case sidebarsEnum.default: {
        return this.renderDefaultSidebar()
      }
      case sidebarsEnum.newWidthdrawal:
      case sidebarsEnum.newClientWithdrawal: {
        const key = `newWidthdrawal ${clientId}`
        return (<WithdrawalEditor
          key={key}
          client={client}
          hideClient
          onClose={onClose}
          config={config}
          onCreate={() => Promise.all([
            this.context.clientProvider.subProviders.basicData.fetch(),
            this.context.clientProvider.subProviders.withdrawals.fetch(),
            this.context.clientProvider.subProviders.activityLogs.fetch(),
          ])}
          fetchActivityLogs={this.fetchWithdrawalActivityLogs}
        />)
      }
      case sidebarsEnum.showWidthdrawal: {
        const {showWithdrawalId} = sidebarData
        const key = `showWidthdrawal ${showWithdrawalId}`
        return (<WithdrawalEditor
          key={key}
          withdrawal={find(withdrawals, (w) => w.id === showWithdrawalId)}
          withdrawals={withdrawals}
          deposits={this.state.nonTransferDeposits || deposits}
          activityLogs={this.state.withdrawalActivityLogs || client.activityLogs}
          client={client}
          hideClient
          onClose={onClose}
          config={config}
          onCreate={() => Promise.all([
            this.context.clientProvider.subProviders.activityLogs.fetch(),
            this.context.clientProvider.subProviders.withdrawals.fetch(),
          ])}
          onUpdate={() => Promise.all([
            this.context.clientProvider.subProviders.activityLogs.fetch(),
            this.context.clientProvider.subProviders.withdrawals.fetch(),
          ])}
          fetchActivityLogs={this.fetchWithdrawalActivityLogs}
        />)
      }
      case sidebarsEnum.newAccount: {
        return (<CreateAccount
          clientCompany={client.personalDetails.company}
          clientId={clientId}
          accounts={client.accounts}
          onClose={onClose}
          clientAddress={client.personalDetails.address}
          clientWhiteLabel={client.personalDetails.whiteLabel}
          partnerDefaultMT4Server={client.personalDetails?.registrationCampaigns[0]?.partnerCustomization?.classicDefaultMT4Server}
        />)
      }
      case sidebarsEnum.addAppendix: {
        const key = `addAppendix ${clientId}`
        return (<AppendixForm
          key={key}
          client={client}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.editAppendix: {
        const key = `editAppendix ${clientId}`
        return (<AppendixForm
          key={key}
          client={client}
          onClose={onClose}
          appendix={first(partnersNotices)}
        />)
      }
      case sidebarsEnum.editAccount: {
        const {showAccountId} = sidebarData
        return (<EditAccount
          onClose={onClose}
          account={find(client.accounts, (a) => a.id === showAccountId)}
          client={client.personalDetails}
          onUpdate={() => Promise.all([
            this.context.clientProvider.subProviders.accounts.fetch(),
            this.context.clientProvider.subProviders.activityLogs.fetch(),
          ])}
        />)
      }
      case sidebarsEnum.newDeposit:
      case sidebarsEnum.newClientDeposit: {
        const key = `newDeposit ${clientId}`
        return (<CreateDeposit
          key={key}
          accounts={client.depositAccounts || client.accounts || []}
          availablePsps={client.generalInfo.whiteLabelConfig.availablePsps}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.newPassword: {
        const key = `newPassword ${clientId}`
        return (<ResetPassword
          key={key}
          clientId={clientId}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.resetTotp: {
        const key = `newPassword ${clientId}`
        return (<ResetTotp
          key={key}
          clientId={clientId}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.securityCode: {
        const key = `securityCode ${clientId}`
        return (<SecurityCode
          key={key}
          clientId={clientId}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.newDocument: {
        const key = 'newDocument'
        return (<DocumentEditor
          key={key}
          clientId={clientId}
          onClose={onClose}
          agents={agents}
          documents={documents}
          paymentMethods={paymentMethods}
          client={client}
        />)
      }
      case sidebarsEnum.showDocument: {
        const {showDocumentId} = sidebarData
        const key = `showDocument ${showDocumentId}`
        return (<div className={style.documentEditor}>
          <DocumentEditor
            key={key}
            document={find(client.documents, (d) => d.id === showDocumentId)}
            activityLogs={this.state.documentActivityLogs || client.activityLogs}
            clientId={clientId}
            onClose={onClose}
            agents={agents}
            documents={documents}
            paymentMethods={paymentMethods}
            client={client}
            fetchActivityLogs={this.fetchDocumentActivityLogs}
          />
        </div>)
      }
      case sidebarsEnum.editClient: {
        const {personalDetails} = client
        const key = `editClient ${clientId}`
        return (
          <ClientEditor
            key={key}
            client={personalDetails}
            header={`Editing Details of ${getFullName(personalDetails)}`}
            fields={personalDetailsFields.filter((field) => field.category === fieldCategories.details)}
            saveAction={actions.client.updateClientDetails}
            onClose={onClose}
          />
        )
      }
      case sidebarsEnum.accountChecks: {
        const {personalDetails} = client
        const key = `accountChecks ${clientId}`
        return (
          <ClientEditor
            key={key}
            client={personalDetails}
            header={`Editing Account Checks of ${getFullName(personalDetails)}`}
            fields={personalDetailsFields.filter((field) => field.category === fieldCategories.accountChecks)}
            saveAction={actions.client.updateClientAccountChecks}
            onClose={onClose}
            viewer={viewer}
          />
        )
      }
      case sidebarsEnum.showDeposit: {
        const key = `showDeposit ${sidebarData.showDepositId}`
        return (!canQueryAffiliateManagerClients(viewer) && <DepositDetails
          key={key}
          clientId={clientId}
          depositId={sidebarData.showDepositId}
          bonusOfferId={sidebarData.showBonusOfferId}
          activityLogs={this.state.depositActivityLogs || client.activityLogs}
          onClose={onClose}
          deposit={find(deposits, {id: sidebarData.showDepositId})}
          fetchActivityLogs={this.fetchDepositActivityLogs}
        />)
      }
      case sidebarsEnum.showAppropTest: {
        const {showDocumentId} = sidebarData
        const {appropTests, personalDetails} = client
        const key = `showAppropTest ${showDocumentId}`
        let appropTest, approvedAppropTest
        if (showDocumentId === 'apt-legacy') {
          appropTest = {id: 'legacy', answers: client.personalDetails.legacyAppropTest}
        } else {
          appropTest = find(appropTests, (a) => `apt-${a.id}` === showDocumentId)
          approvedAppropTest = appropTest && appropTests.find(
            (a) => a.createdAt && a.createdAt.isBefore(appropTest.createdAt) &&
              a.status === appropTestStatuses.approved.key
          )
        }
        return (!canQueryAffiliateManagerClients(viewer) && <AppropTestDetails
          key={key}
          appropTest={appropTest}
          viewer={this.props.viewer}
          approvedAppropTest={approvedAppropTest}
          header={`AP Test of ${getFullName(personalDetails)}`}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.showDueDiligence: {
        const {showDocumentId} = sidebarData
        const {dueDiligences, personalDetails} = client
        const key = `showDueDiligence ${showDocumentId}`
        const dueDiligence = find(dueDiligences, (a) => `edd-${a.id}` === showDocumentId)
        const approvedDueDiligence = dueDiligence && dueDiligences.find(
          (a) => a.createdAt && a.createdAt.isBefore(dueDiligence.createdAt) &&
            a.status === dueDiligenceStatuses.approved.key
        )
        const getPreviousRecordId = findIndex(dueDiligences, (a) => `edd-${a.id}` === showDocumentId) + 1
        const previousEdd = get(dueDiligences, getPreviousRecordId)

        return (<DueDiligenceDetails
          key={key}
          dueDiligence={dueDiligence}
          previousEdd={previousEdd || {}}
          viewer={this.props.viewer}
          approvedDueDiligence={approvedDueDiligence}
          header={`Due Diligence of ${getFullName(personalDetails)}`}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.editDueDiligence: {
        const {showDocumentId} = sidebarData
        const {dueDiligences, personalDetails} = client
        const key = `editDueDiligence ${showDocumentId}`
        const dueDiligence = find(dueDiligences, (a) => `editDueDiligence-${a.id}` === showDocumentId)
        return (<DueDiligenceForm
          key={key}
          dueDiligence={dueDiligence}
          viewer={this.props.viewer}
          clientId={clientId}
          header={`Due Diligence of ${getFullName(personalDetails)}`}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.showSuitabilityTest: {
        const {showDocumentId} = sidebarData
        const {suitabilityTests, personalDetails} = client
        const key = `showSuitabilityTest ${showDocumentId}`
        const suitabilityTest = find(suitabilityTests, (a) => `sui-${a.id}` === showDocumentId)
        const approvedSuitabilityTest = suitabilityTest && suitabilityTests.find(
          (a) => a.createdAt && a.createdAt.isBefore(suitabilityTest.createdAt) &&
            a.status === suitabilityTestStatuses.approved.key
        )
        return <SuitabilityTestDetails
          key={key}
          suitabilityTest={suitabilityTest}
          viewer={this.props.viewer}
          approvedSuitabilityTest={approvedSuitabilityTest}
          header={`Suitability Test of ${getFullName(personalDetails)}`}
          onClose={onClose}
        />
      }
      case sidebarsEnum.showProfileChange: {
        const {showDocumentId, phones, phoneRevealed} = sidebarData
        const {profileChanges, personalDetails} = client
        const key = `showProfileChange ${showDocumentId}`
        const profileChange = find(profileChanges, (pc) => `pc-${pc.id}` === showDocumentId)
        return (!canQueryAffiliateManagerClients(viewer) && <ProfileChangeDetails
          key={key}
          client={personalDetails}
          profileChange={profileChange}
          header={`Profile Change of ${getFullName(personalDetails)}`}
          onClose={onClose}
          revealPhone={(clientId) => this.revealPhone(clientId, profileChange.id)}
          viewer={this.props.viewer}
          revealedPhones={phones}
          phoneRevealed={phoneRevealed}
        />)
      }
      case sidebarsEnum.updateWalkthroughs: {
        return (<UpdateWalkthrough
          walkthroughs={client.walkthroughs}
          onClose={onClose}
          clientId={client.generalInfo.id}
        />)
      }
      case sidebarsEnum.createTransfer:
      case sidebarsEnum.createTransferClient: {
        return (<CreateTransfer
          client={client}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.changeCompany: {
        const {personalDetails} = client
        const show = true
        return (
          <ChangeCompanyModal
            client={personalDetails}
            show={show}
            onHide={onClose}
            header={`Changing Company for ${getFullName(personalDetails)}`}
            saveAction={actions.client.changeCompany}
          />
        )
      }

      case sidebarsEnum.attachToAffiliate: {
        const {personalDetails} = client

        return (
          <AttachToAffiliate
            client={personalDetails}
            header={`Attach to affiliate for ${getFullName(personalDetails)}`}
            saveAction={actions.client.attachToAffiliate}
            onHide={onClose}
          />
        )
      }

      case sidebarsEnum.updateEconomicProfile: {
        const {personalDetails} = client
        return (
          <UpdateEconomicProfile
            globalQuestionnaire={client.generalInfo.globalQuestionnaire}
            header={`Editing ${getFullName(personalDetails)} Economic Profile`}
            client={client}
            onClose={onClose}
            clientId={client.generalInfo.id}
          />
        )
      }

      case sidebarsEnum.sendInvitation: {
        const key = `sendInvitation ${clientId}`
        return (<SendInvitation
          key={key}
          clientId={clientId}
          onClose={onClose}
        />)
      }

      case sidebarsEnum.createVpsSubscription: {
        const key = `createVpsSubscription ${clientId}`

        return (
          <CreateVpsSubscription
            key={key}
            client={client}
            onClose={onClose}
          />
        )
      }

      case sidebarsEnum.updatePotentials: {
        return (<UpdatePotential
          potentials={client.potentials}
          onClose={onClose}
          clientId={client.generalInfo.id}
          client={client}
          generalInfo={client.generalInfo}
        />)
      }
      case sidebarsEnum.generateTaxDocument: {
        const key = `taxDocument ${clientId}`
        return (<GenerateTaxDocument
          key={key}
          clientId={clientId}
          backendUrl={this.context.config.backendUrl}
          onClose={onClose}
          match={match}
        />)
      }
      case sidebarsEnum.linkClient: {
        const key = `linkClient ${clientId}`
        return (<LinkClient
          key={key}
          clientId={clientId}
          onClose={onClose}
        />)
      }
      case sidebarsEnum.editPromotingCountries: {
        return (<EditPromotingCountries
          clientId={clientId}
          countriesPromoted={client.affiliateCountries}
          onClose={onClose}
          updateCountriesPromoted={actions.client.updateAffiliateCountries}
        />)
      }
      case sidebarsEnum.loginVerificationDetails: {
        const {personalDetails} = client
        const key = `loginVerificationDetails ${clientId}`
        return (get(client, 'generalInfo.blockedPhoneBirthdayReveal') && <ClientEditor
          key={key}
          client={{id: clientId}}
          header={`Login Verification Details of ${getFullName(personalDetails)}`}
          fields={personalDetailsFields.filter((field) => field.loginVerification)}
          saveAction={actions.client.verifyClientDetails}
          onClose={onClose}
          verifyFields={true}
          buttonText={'Confirm'}
        />)
      }
      case sidebarsEnum.sendEmailTemplate: {
        const {personalDetails} = client
        const {viewer} = this.props
        const show = true
        return (
          <SendEmailTemplateModal
            client={personalDetails}
            viewer={viewer}
            show={show}
            onHide={onClose}
            header={`Sending Email Template To ${getFullName(personalDetails)}`}
            signFile={actions.client.signDepartmentTemplateEmailFileUrl}
            saveAction={actions.client.sendDepartmentTemplateEmail}
          />
        )
      }
      default:
        console.warn('Unexpected sidebar ', sidebar) // eslint-disable-line no-console
    }
  }

  showAppointmentEditor = (appointment) => {
    const {appointments: appointmentsProvider} = this.context.clientProvider.subProviders
    this.context.toggleAppointmentEditor(appointment, appointmentsProvider)
  }

  addTitleBadge(title, count, includesHeldIncomplete) {
    if (!count || (title === clientTabs.documents.label && canQueryAffiliateManagerClients(this.props.viewer))) {
      return title
    }

    const badge = (
      <OverlayTrigger
        placement="bottom"
        overlay={includesHeldIncomplete ? tabTooltipHeldIncomplete : tabTooltip}
      >
        <Badge pill variant="secondary">{count}</Badge>
      </OverlayTrigger>
    )
    const tabTitle = (<span>{title} {badge}</span>)

    return tabTitle
  }

  renderPositionsTab(activetab) {
    const {uiDispatch, filterDispatch, filterState, uiState, client} = this.props
    return (
      <Tab
        eventKey={clientTabs.positions.key}
        title={clientTabs.positions.label}
      >
        <Positions
          uiDispatch={uiDispatch}
          uiState={uiState}
          filterDispatch={filterDispatch}
          filterState={filterState}
          accounts={client.accounts}
          isActive={clientTabs.positions.key === activetab}
        />
      </Tab>
    )
  }

  renderDocumentsTab(activeTab) {
    const {client, sidebarData, clientId} = this.props
    const {personalDetails: {pendingDocumentsCount = 0, pendingApTestCount = 0, pendingProfileChangesCount = 0,
      pendingDueDiligenceCount = 0}} = client
    const pendingTotal = pendingDocumentsCount + pendingApTestCount + pendingProfileChangesCount + pendingDueDiligenceCount
    const title = this.addTitleBadge(clientTabs.documents.label, pendingTotal)
    return (
      <Tab
        eventKey={clientTabs.documents.key}
        title={title}
      >
        <Documents
          company={client.personalDetails.company}
          clientId={clientId}
          client={client}
          kycStatus={client.personalDetails.kycStatus}
          documents={client.documents}
          profileChanges={client.profileChanges}
          appropTests={client.appropTests}
          dueDiligences={client.dueDiligences}
          suitabilityTests={client.suitabilityTests}
          legacyAppropTest={client.personalDetails.legacyAppropTest}
          activeId={(sidebarData || {}).showDocumentId}
          switchSidebar={this.switchSidebar}
          missingDocuments={parseJSON(client.personalDetails.missingDocuments)}
          isActive={clientTabs.documents.key === activeTab}
          fetchActivityLogs={this.fetchDocumentActivityLogs}
          pendingTotal={pendingDocumentsCount}
        />
      </Tab>
    )
  }

  renderWithdrawalsTab(activeTab) {
    const {client, sidebarData, withdrawalsTypeFilterState, withdrawalsTypeFilter, filterState,
      filterDispatch, withdrawalStatusFilter, withdrawalVendorsFilter} = this.props
    const {personalDetails: {pendingWithdrawalsCount = 0}} = client
    const title = this.addTitleBadge(clientTabs.withdrawals.label, pendingWithdrawalsCount)
    return (
      <Tab
        eventKey={clientTabs.withdrawals.key}
        title={title}
      >
        <Withdrawals
          client={client}
          activeId={(sidebarData || {}).showWithdrawalId}
          switchSidebar={this.switchSidebar}
          withdrawalsTypeFilterState={withdrawalsTypeFilterState}
          withdrawalsTypeFilter={withdrawalsTypeFilter}
          withdrawalVendorsFilter={withdrawalVendorsFilter}
          filterDispatch={filterDispatch}
          filterState={filterState}
          isActive={clientTabs.withdrawals.key === activeTab}
          withdrawalStatusFilter={withdrawalStatusFilter}
          setNonTransferDeposits={(nonTransferDeposits) => this.setState({nonTransferDeposits})}
          fetchActivityLogs={this.fetchWithdrawalActivityLogs}
        />
      </Tab>
    )
  }

  renderDepositsTab(activeTab) {
    const {filterDispatch, filterState, client, sidebarData, depositsStatusFilter, depositsTypeFilter,
      depositsVendorFilter, depositsVendorFilter2, hasPartialRefundsFilter, depositsCreatedFromFilter,
      depositsCreatedToFilter} = this.props
    const {personalDetails: {pendingBonusOffersCount = 0, heldIncompleDepositsCount = 0}} = client
    const totalPending = pendingBonusOffersCount + heldIncompleDepositsCount
    const title = this.addTitleBadge(clientTabs.deposits.label, totalPending, true)
    return (
      <Tab
        eventKey={clientTabs.deposits.key}
        title={title}
      >
        <Deposits
          depositsStatusFilter={depositsStatusFilter}
          depositsTypeFilter={depositsTypeFilter}
          depositsVendorFilter={depositsVendorFilter}
          depositsVendorFilter2={depositsVendorFilter2}
          hasPartialRefundsFilter={hasPartialRefundsFilter}
          depositsCreatedFromFilter={depositsCreatedFromFilter}
          depositsCreatedToFilter={depositsCreatedToFilter}
          client={client}
          activeDepositId={(sidebarData || {}).showDepositId}
          activeBonusOfferId={(sidebarData || {}).showBonusOfferId}
          switchSidebar={this.switchSidebar}
          filterDispatch={filterDispatch}
          filterState={filterState}
          isActive={clientTabs.deposits.key === activeTab}
          fetchActivityLogs={this.fetchDepositActivityLogs}
        />
      </Tab>
    )

  }

  renderAccountsTab(activeTab) {
    const {client, uiDispatch, uiState, state: {config}} = this.props
    return (
      <Tab
        eventKey={clientTabs.accounts.key}
        title={clientTabs.accounts.label}
      >
        <Accounts
          uiDispatch={uiDispatch}
          uiState={uiState}
          client={client}
          switchSidebar={this.switchSidebar}
          isActive={clientTabs.accounts.key === activeTab}
          config={config}
        />
      </Tab>
    )
  }

  renderVpsTab(activeTab) {
    const {client, uiDispatch, uiState} = this.props
    return (
      <Tab
        eventKey={clientTabs.vps.key}
        title={clientTabs.vps.label}
      >
        <VPS
          uiDispatch={uiDispatch}
          uiState={uiState}
          client={client}
          switchSidebar={this.switchSidebar}
          isActive={clientTabs.vps.key === activeTab}
        />
      </Tab>
    )
  }

  renderPersonalDetailsTab() {
    const {clientId, client} = this.props
    return (
      <Tab
        eventKey={clientTabs.details.key}
        title={clientTabs.details.label}
      >
        <PersonalDetails
          clientId={clientId}
          client={client}
          switchSidebar={this.switchSidebar}
        />
      </Tab>
    )
  }

  renderAffiliatePersonalDetailsTab() {
    const {clientId, client} = this.props
    return (
      <Tab
        eventKey={clientTabs.details.key}
        title={clientTabs.details.label}
      >
        <RestrictedPersonalDetails
          clientId={clientId}
          client={client}
          switchSidebar={this.switchSidebar}
        />
      </Tab>
    )
  }

  renderAcknowledgementsTab(activetab) {
    const {client, uiDispatch, uiState} = this.props
    return (
      <Tab
        eventKey={clientTabs.acknowledgements.key}
        title={clientTabs.acknowledgements.label}
      >
        <Acknowledgements
          client={client}
          uiDispatch={uiDispatch}
          uiState={uiState}
          switchSidebar={this.switchSidebar}
          isActive={clientTabs.acknowledgements.key === activetab}
          allAccounts={client.accounts}
        />
      </Tab>
    )
  }

  renderReferralsTab(activetab) {
    const {client, uiDispatch, uiState} = this.props
    return (
      <Tab
        eventKey={clientTabs.referrals.key}
        title={clientTabs.referrals.label}
      >
        <Referrals
          client={client}
          uiDispatch={uiDispatch}
          uiState={uiState}
          switchSidebar={this.switchSidebar}
          isActive={clientTabs.referrals.key === activetab}
        />
      </Tab>
    )
  }

  isClientDetails = (pathname) => /^\/clients\/\d+$/.test(pathname) || /^\/clients\/\d+\/details/.test(pathname)

  render() {
    const {uiDispatch, uiState, clientId, client, tab, state: {config}, viewer, sidebar, location: {pathname}} = this.props
    if (isEmpty(client)) return <Loading speed={1} style={{padding:'20px'}}/>
    const {generalInfo: {convertedAt}} = client
    const clientConverted = isConverted(convertedAt)

    const useOverflowSidePanel = [sidebarsEnum.newDocument, sidebarsEnum.showDocument].includes(sidebar)

    return (
      <Container>
        <Row>
          <Col xs={8}>
            <GeneralInfo
              uiDispatch={uiDispatch}
              uiState={uiState}
              generalInfo={client.generalInfo}
              showAppointmentEditor={this.showAppointmentEditor}
              config={config}
            />

            <Tabs
              id="t-client"
              className={this.isClientDetails(pathname) ? classNames('t-client', 'clientDetailsTabContainer') : 't-client'}
              activeKey={tab}
              onSelect={
                (key) => this.context.router.push(`/clients/${clientId}/${key}`)
              }
            >
              {!canQueryAffiliateManagerClients(viewer) && this.renderPersonalDetailsTab(tab)}
              {canQueryAffiliateManagerClients(viewer) && this.renderAffiliatePersonalDetailsTab(tab)}
              {!canQueryAffiliateManagerClients(viewer) && clientConverted && this.renderAccountsTab(tab)}
              {clientConverted && this.renderDepositsTab(tab)}
              {clientConverted && this.renderWithdrawalsTab(tab)}
              {this.renderDocumentsTab(tab)}
              {!canQueryAffiliateManagerClients(viewer) && clientConverted && this.renderPositionsTab(tab)}
              {clientConverted && this.renderAcknowledgementsTab(tab)}
              {get(client, 'personalDetails.referralDetails.countReferrals') > 0 && this.renderReferralsTab(tab)}
              {!canQueryAffiliateManagerClients(viewer) && this.renderVpsTab(tab)}
            </Tabs>
          </Col>
          <Col xs={4} className="sidebar-col">
            <div className={useOverflowSidePanel ? style.overflowFixedSidePanel : style.fixedSidePanel}>{this.renderSidebar()}</div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  uiMount(() => uiStatePath, () => ['clientFilter']),

  predispatch((props) => {
    props.uiDispatch(
      'Setting default for sidebar, account',
      (state) => {
        const {match: {params: {tab, sidebarArg}}} = props

        return ({
          ...state,
          ...buildSidebarFromParams(tab, sidebarArg),
          revealedEvents: {},
        })
      }
    )
    props.filterDispatch(
      'Setting default for filters',
      (state) => ({
        ...state,
        depositsStatusFilter: ['completed', 'failed', 'chargeback', 'held', 'awaitingPayment',
          'incomplete', 'cancelled', 'authorized', 'partiallyReversed', 'reversed'],
        depositsTypeFilter: [
          transferTypes.transfers.key,
          transferTypes.nonTransfers.key,
          depositVendors.copyTradingPerformanceFee.key,
          'commissions',
          'nonCommissions',
        ],
        withdrawalsTypeFilterState: [
          transferTypes.transfers.key,
          transferTypes.nonTransfers.key,
          withdrawalTypes.performanceFee.key,
        ],
        depositsVendorFilter: map(depositVendors, 'key'),
        depositsVendorFilter2: [],
        hasPartialRefundsFilter: false,
        depositsCreatedFromFilter: '',
        depositsCreatedToFilter: '',
        withdrawalsTypeFilter: map(withdrawalTypes, 'key'),
        tradingPositionStatusFilter: tradingPositionStatuses.open.key,
        positionsPageSizeFilter: pageSizeEnum.p25.key,
        visibleAccount: null,
        withdrawalStatusFilter: map(withdrawalStatuses , 'key')
      })
    )
  }),

  provideProps((state, uiState, filterState, props) => {
    const {match: {params: {clientId, tab, sidebarArg}}} = props
    const {client, initialAffiliateId} = state
    const {sidebar, sidebarData, tradingStatusModal} = uiState
    const {tradingPositionStatusFilter, depositsStatusFilter, depositsTypeFilter, positionsPageSizeFilter,
      depositsVendorFilter, depositsVendorFilter2, forexPositionsPage, spotOptionsPositionsPage, cuboidPositionsPage,
      tradesmarterPositionsPage, withdrawalsTypeFilterState, withdrawalsTypeFilter, visibleAccount, depositsCreatedFromFilter,
      depositsCreatedToFilter, depositsPage, withdrawalsPage, withdrawalStatusFilter, withdrawalVendorsFilter,
      hasPartialRefundsFilter, userDepartmentFilter, emailsPage, fetchingMoreEmails} = filterState

    const clientObj = (client && client[clientId]) || {}
    const sortedAccounts = [...(clientObj.accounts || [])].sort(compareAccounts)
    const selectedAccount = visibleAccount != null
      ? sortedAccounts.find((account) => account.id === visibleAccount)
      : sortedAccounts[0]

    return ({
      state,
      clientId: Number(clientId),
      client: clientObj,
      tab: tab in clientTabs ? tab : clientTabs.details.key,
      sidebarArg,
      sidebar,
      sidebarData,
      tradingStatusModal,
      tradingPositionStatusFilter,
      positionsPageSizeFilter,
      depositsStatusFilter,
      depositsTypeFilter,
      depositsVendorFilter,
      depositsVendorFilter2,
      hasPartialRefundsFilter,
      depositsCreatedFromFilter,
      depositsCreatedToFilter,
      withdrawalsTypeFilterState,
      withdrawalsTypeFilter,
      forexPositionsPage,
      spotOptionsPositionsPage,
      cuboidPositionsPage,
      tradesmarterPositionsPage,
      visibleAccount: get(selectedAccount, 'id'),
      depositsPage,
      withdrawalsPage,
      withdrawalStatusFilter,
      withdrawalVendorsFilter,
      userDepartmentFilter,
      emailsPage,
      fetchingMoreEmails,
      initialAffiliateId,
    })
  }),

  mountDataProviders({clientProvider}),
)(Client)
