import React from 'react'
import moment from 'moment'
import {Col, Form, Row, Tab, Tabs, Collapse} from 'react-bootstrap'
import {omit, isEqual, has, get, filter, includes, map, find, forEach, isArray} from 'lodash'
import {currencies, countries, campaignTypes, accountSubtypes, accountTypes, companies} from '@bdswiss/common-enums'
import style from './campaigns.module.scss'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import {compose, provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

function parseDate(value) {
  const datetime = moment(value)

  if (datetime.isValid()) {
    return datetime
  }

  return value
}

function internalValue(key, value) {
  if (key === 'end' && moment.isMoment(value) && value.isValid()) {
    // calling .endOf mutates the value. moment.js FTW!
    value = moment(value).endOf('day')
  }
  if (value != null && key === 'cookieTimeout') {
    return Number(value)
  }
  return value
}

function tabTitle(title, hasErrors = false) {
  if (hasErrors) {
    return (<span className="text-danger">{title}</span>)
  } else {
    return title
  }
}

class CampaignForm extends PureComponent {
  GLOBALBONUSLIMIT = 5000

  componentWillMount() {
    this.setState({
      showClassicBonusSettings: false,
      showVIPBonusSettings: false,
      showRAWBonusSettings: false,
    })
  }
  getValue(key) {
    if (key in this.props.values) {
      return this.props.values[key]
    } else if (this.props.campaign != null) {
      if (key === 'start' || key === 'end') {
        return parseDate(this.props.campaign[key])
      } else {
        return this.props.campaign[key]
      }
    } else {
      return null
    }
  }

  valueChanged(key, value) {
    value = internalValue(key, value)
    if (this.props.campaign != null && isEqual(value, this.props.campaign[key])) {
      return this.props.onChange(omit(this.props.values, [key]))
    }
    return this.props.onChange({...this.props.values, [key]: value})
  }

  generateInputProps(key) {
    const existingValue = this.getValue(key)
    const notNullishValue = (existingValue !== undefined  && existingValue !== null)
    const res = {
      value: notNullishValue ? existingValue : '',
      checked: this.getValue(key),
      onChange: (e) => {
        if (key === 'globalBonusLimit' && e.target.value > this.GLOBALBONUSLIMIT) {
          e.target.value = this.GLOBALBONUSLIMIT
        }
        if (e && e.target && e.target.type === 'checkbox' && ('checked' in e.target)) {
          this.valueChanged(key, e.target.checked)
        } else if (e && e.target) {
          this.valueChanged(key, e.target.value)
        } else if (e && e.value) {
          this.valueChanged(key, e.value)
        } else if (e) {
          this.valueChanged(key, isArray(e) ? map(e, 'value') : e)
        } else {
          this.valueChanged(key, '')
        }
      },
    }
    if (key in this.props.errors) {
      res.isInvalid = true
    }
    return res
  }

  handleNoCallChange = (e) => {
    this.valueChanged('noCall', e.target.checked)
  }

  extractMinDeposit(deposits, currency) {
    const obj = (deposits || []).find((deposit) => deposit.currency === currency)
    if (obj) {
      return obj.amount
    } else {
      return null
    }
  }

  extractMinDepositAccountType(deposits, accountType) {
    const obj = (deposits || []).find((deposit) => deposit.accountType === accountType)
    if (obj) {
      return obj.amount
    } else {
      return null
    }
  }

  clientIdChanged(clientIdKey, clientIdValue, campaignTypeKey, campaignTypeValue) {
    clientIdValue = internalValue(clientIdKey, clientIdValue)
    campaignTypeValue = internalValue(campaignTypeKey, campaignTypeValue)
    if (this.props.campaign != null) {
      return this.props.onChange({...this.props.values,
        [clientIdKey]: clientIdValue, [campaignTypeKey]: campaignTypeValue})
    }
    return this.props.onChange({...this.props.values, [clientIdKey]: clientIdValue})
  }

  generateClientIdInputProps(clientIdKey, campaignTypeKey) {
    const res = {
      value: this.getValue(clientIdKey) || '',
      onChange: (e) => {
        if (e && e.target) {
          this.clientIdChanged(clientIdKey, e.target.value, campaignTypeKey, this.getValue(campaignTypeKey))
        } else if (e && e.value) {
          this.clientIdChanged(clientIdKey, e.value, campaignTypeKey, this.getValue(campaignTypeKey))
        } else if (e) {
          this.clientIdChanged(clientIdKey, e, campaignTypeKey, this.getValue(campaignTypeKey))
        } else {
          this.clientIdChanged(clientIdKey, '', campaignTypeKey, '')
        }
      },
    }
    if (clientIdKey in this.props.errors) {
      res.isInvalid = true
    }
    return res
  }

  minDepositChanged(currency, amount, section) {
    const depositsKey = section
    const deposits = (this.getValue(depositsKey) || []).slice()
    const i = deposits.findIndex((deposit) => deposit.currency === currency)
    if (amount) {
      const deposit = {currency, amount: Number(amount)}
      if (i >= 0) {
        deposits[i] = deposit
      } else {
        deposits.push(deposit)
      }
    } else if (i >= 0) {
      deposits.splice(i, 1)
    }
    this.valueChanged(depositsKey, deposits)
  }

  generateMinDepositProps(currency, section) {
    const depositsKey = section
    const val = this.extractMinDeposit(this.getValue(depositsKey), currency.key)
    const res = {
      type: 'number',
      value: val || '',
      addonAfter: currency.symbol || currency.key,
      onChange: (e) => this.minDepositChanged(currency.key, e.target.value, section),
    }

    if (this.props.errors[depositsKey] && this.props.errors[depositsKey][currency.key]) {
      res.isInvalid = true
    }

    return res
  }

  minDepositAccountTypeChanged(accountType, amount) {
    const deposits = (this.getValue('minimumDepositsAccountType') || []).slice()
    const i = deposits.findIndex((deposit) => deposit.accountType === accountType)
    if (amount) {
      const deposit = {accountType, amount: Number(amount)}
      if (i >= 0) {
        deposits[i] = deposit
      } else {
        deposits.push(deposit)
      }
    } else if (i >= 0) {
      deposits.splice(i, 1)
    }
    this.valueChanged('minimumDepositsAccountType', deposits)
  }


  renderErrorMessage(key) {
    const {errors} = this.props
    if (has(errors, key)) {
      return (
        <p className="help-block"> {get(errors, key)} </p>
      )
    }
  }

  findAccountTypes(notAllowedSubtypes = []) {
    const allowedAccounts = []
    forEach(accountSubtypes, (subtype) => {
      const type = !includes(notAllowedSubtypes, subtype.value) && !subtype.hideFromClients && find(accountTypes, (type) => type.clientOpenPermitted && includes(type.supportedSubtypes, subtype.value))
      type && allowedAccounts.push({...subtype, company: companies[type.company].brand})
    })
    return allowedAccounts
  }

  render() {
    return (
      <div>
        <Tabs id="t-campaigns-campaign-editor-tab" defaultEventKey={1}>
          <Tab
            eventKey={1}
            title={tabTitle('Properties', this.props.errors._direct_count !== 0)}
          >
            {this.renderBody()}
          </Tab>
        </Tabs>
      </div>
    )
  }

  renderBody() {
    const {showClassicBonusSettings, showVIPBonusSettings, showRAWBonusSettings} = this.state
    const {minimumFirstTimeDeposit} = this.props.config
    const affiliateAccountSubTypes = filter(accountSubtypes, {affiliateAccountType: true})
    const affiliateGroups = [
      {
        label: 'None',
        value: 'none',
      },
      ...StylishSelect.enumToStylishOptions(affiliateAccountSubTypes),
    ]
    return (
      <Row id="t-campaigns-form">
        <Col xs={12}>
          <Col xs={12} style={{textAlign: 'center', paddingBottom: 15}}>
            <strong style={{fontSize: 17}}>Global Settings</strong>
          </Col>
          <form>
            <Row className="mb-3">
              <Col xs={8} sm={3}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    id="t-campaigns-form-name"
                    type="text"
                    maxLength="255"
                    placeholder="Enter name"
                    {...this.generateInputProps('name')}
                  />
                  {this.renderErrorMessage('name')}
                </Form.Group>
              </Col>
              <Col xs={8} sm={3}>
                <StylishSelect.Input
                  id="t-campaigns-form-type"
                  label="Type"
                  {...this.generateInputProps('type')}
                  options={StylishSelect.enumToStylishOptions(campaignTypes)}
                />
              </Col>
              <Col xs={8} sm={3}>
                <Form.Group>
                  <Form.Label>Affiliate client id</Form.Label>
                  <Form.Control
                    id="t-campaigns-form-affiliate"
                    type="text"
                    maxLength="255"
                    placeholder="Enter client id"
                    {...this.generateClientIdInputProps('clientId', 'type')}
                  />
                  {this.renderErrorMessage('clientId')}
                </Form.Group>
              </Col>
              {(get(this.props, 'values.type') === campaignTypes.affiliate.value
                  || get(this.props, 'campaign.type') === campaignTypes.affiliate.value) &&
                  <Col xs={8} sm={3}>
                    <StylishSelect.Input
                      id="t-campaigns-form-affiliate-group"
                      label="Affiliate MT4 Group"
                      {...this.generateInputProps('affiliateGroup')}
                      options={affiliateGroups}
                    />
                  </Col>
              }
              <Col xs={8} sm={3}>
                <Form.Group>
                  <Form.Label>Score</Form.Label>
                  <Form.Control
                    id="t-campaigns-form-no-call-ratio"
                    type="text"
                    maxLength="4"
                    value={this.getValue('noCallRatio')}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} sm={6}>
                <Row>
                  <Col xs={3} sm={3}>
                    <Form.Check
                      id={'t-campaigns-form-show-welcome-page'}
                      label={<span><strong> {' Show Welcome Page on Signup'} </strong></span>}
                      {...this.generateInputProps('showWelcomePage')}
                    />
                  </Col>
                  <Col xs={3} sm={3}>
                    <Form.Check
                      id={'t-campaigns-form-redirect-webTrader-page'}
                      label={<span><strong> {' Redirect to webtrader on ftd'} </strong></span>}
                      {...this.generateInputProps('redirectWebTraderFtd')}
                    />
                  </Col>
                  <Col xs={3} sm={3}>
                    <Form.Check
                      id={'t-campaigns-form-avoid-phone-verification'}
                      label={<span><strong> {' Avoid Phone Verification'} </strong></span>}
                      {...this.generateInputProps('avoidPhoneVerification')}
                    />
                  </Col>
                  <Col xs={3} sm={3}>
                    <Form.Check
                      id={'t-campaigns-form-email-verification'}
                      label={<span><strong> {' Email Verification needed'} </strong></span>}
                      {...this.generateInputProps('emailVerification')}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={6}>
                <Row>
                  <Col xs={3} sm={3}>
                    <Form.Check
                      id={'t-campaigns-form-no-call'}
                      label={<span><strong> {'No Call'} </strong></span>}
                      value={this.getValue('noCall') || ''}
                      defaultChecked={this.getValue('noCall')}
                      onChange={this.handleNoCallChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <DateTime
                  label="Start"
                  timeFormat={false}
                  {...this.generateInputProps('start')}
                />
              </Col>
              <Col xs={3}>
                <DateTime
                  label="End"
                  timeFormat={false}
                  {...this.generateInputProps('end')}
                />
              </Col>
              <Col xs={3}>
                <StylishSelect.Input
                  id="t-campaigns-form-country"
                  label="Country"
                  clearable
                  {...this.generateInputProps('country')}
                  options={StylishSelect.enumToStylishOptions(countries)}
                />
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Cookie Timeout</Form.Label>
                  <Form.Control
                    id="t-campaigns-form-cookie-timeout"
                    type="number"
                    label="Cookie Timeout"
                    addonAfter="sec"
                    placeholder="Enter time in seconds"
                    {...this.generateInputProps('cookieTimeout')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Global bonus limit:</Form.Label>
                  <Form.Control
                    id="t-campaigns-form-global-bonus-limit"
                    type="number"
                    max={this.GLOBALBONUSLIMIT}
                    {...this.generateInputProps('globalBonusLimit')}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>Promo code:</Form.Label>
                  <Form.Control
                    id="t-campaigns-form-promo-code"
                    type="text"
                    {...this.generateInputProps('promoCode')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className={style.row}>
              <Row className='mb-3'>
                <Col xs={12}>
                  <div
                    className={style.bonusSettingIconBar}
                    onClick={() => this.setState({showClassicBonusSettings: !showClassicBonusSettings})}
                    id='t-advance-settings-icon-bar'
                  >
                    <Col xs={12}>
                      <strong style={{fontSize: 17}}>
                        Classic Accounts Settings
                      </strong>
                      <FontAwesomeIcon
                        icon={`angle-double-${showClassicBonusSettings ? 'up' : 'down'}`}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Collapse in={showClassicBonusSettings}>
                    <div id='t-advance-filters'>
                      <Row className='mb-3'>
                        <Col xs={12} style={{textAlign: 'left', paddingBottom: 15}}>
                          <div>
                            <strong>
                              <i>Minimum Deposit per Currency</i>{' '}
                            </strong>
                            <span style={{color: 'grey'}}>
                              <i>(read-only status threshold)</i>
                            </span>
                            :
                          </div>
                        </Col>
                        <Col xs={12} sm={4}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in EUR *</Form.Label>
                            <Form.Control
                              id='t-campaigns-form-min-deposit-eur'
                              {...this.generateMinDepositProps(
                                currencies.EUR,
                                'minimumDeposits'
                              )}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={4}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in USD *</Form.Label>
                            <Form.Control
                              id='t-campaigns-form-min-deposit-usd'
                              {...this.generateMinDepositProps(
                                currencies.USD,
                                'minimumDeposits'
                              )}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={4}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in GBP *</Form.Label>
                            <Form.Control
                              id='t-campaigns-form-min-deposit-gbp'
                              {...this.generateMinDepositProps(
                                currencies.GBP,
                                'minimumDeposits'
                              )}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className={style.minDepositInfo}>
                            * The amounts apply only to first time deposits. If no value is
                            set, the default value will be used for the first time deposit.
                            <br />
                            {Object.keys(minimumFirstTimeDeposit)
                              .map(
                                (currency, i) =>
                                  `${currency} ${minimumFirstTimeDeposit[currency]}`
                              )
                              .join(', ')}
                          </div>
                        </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col xs={12} sm={6}>
                          <Form.Group>
                            <Form.Label>Spot Campaign Id</Form.Label>
                            <Form.Control
                              type='text'
                              disabled
                              {...this.generateInputProps('spotCampaignId')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Group>
                            <Form.Label>KYC threshold in EUR</Form.Label>
                            <Form.Control
                              id='t-campaigns-form-kyc-threshold'
                              type='number'
                              {...this.generateInputProps('kycThreshold')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col xs={12} style={{textAlign: 'left', paddingBottom: 15}}>
                          <div>
                            <strong>
                              <i>First Deposit Bonus</i>{' '}
                            </strong>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Bonus Percentage on top of the deposit (%)</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-ftd-bonus-percentage"
                              type="number"
                              {...this.generateInputProps('ftdBonusPercentage')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in EUR *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-ftd-bonus-min-deposit-eur"
                              {...this.generateMinDepositProps(currencies.EUR, 'ftdBonusMinimumDeposits')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in USD *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-ftd-bonus-min-deposit-usd"
                              {...this.generateMinDepositProps(currencies.USD, 'ftdBonusMinimumDeposits')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in GBP *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-ftd-bonus-min-deposit-gbp"
                              {...this.generateMinDepositProps(currencies.GBP, 'ftdBonusMinimumDeposits')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>


                      <Row className="mb-3">
                        <Col xs={12} style={{textAlign: 'left', paddingBottom: 15}}>
                          <div>
                            <strong>
                              <i>Re-Deposit Bonus</i>{' '}
                            </strong>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Bonus Percentage on top of the deposit (%)</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-redeposit-bonus-percentage"
                              type="number"
                              {...this.generateInputProps('redepositBonusPercentage')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in EUR *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-redeposit-bonus-min-deposit-eur"
                              {...this.generateMinDepositProps(currencies.EUR, 'redepositBonusMinimumDeposits')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in USD *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-redeposit-bonus-min-deposit-usd"
                              {...this.generateMinDepositProps(currencies.USD, 'redepositBonusMinimumDeposits')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in GBP *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-redeposit-bonus-min-deposit-gbp"
                              {...this.generateMinDepositProps(currencies.GBP, 'redepositBonusMinimumDeposits')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </Col>
              </Row>

              {/*  VIP Account */}
              <Row className='mb-3'>
                <Col xs={12}>
                  <div
                    className={style.bonusSettingIconBar}
                    onClick={() => this.setState({showVIPBonusSettings: !showVIPBonusSettings})}
                    id='t-advance-settings-icon-bar'
                  >
                    <Col xs={12}>
                      <strong style={{fontSize: 17}}>
                        VIP Accounts Settings
                      </strong>
                      <FontAwesomeIcon
                        icon={`angle-double-${showVIPBonusSettings ? 'up' : 'down'}`}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Collapse in={showVIPBonusSettings}>
                    <div id='t-advance-filters'>
                      <Row className='mb-3'>
                        <Col xs={12} style={{textAlign: 'left', paddingBottom: 15}}>
                          <div>
                            <strong>
                              <i>Minimum Deposit</i>{' '}
                            </strong>
                            <span style={{color: 'grey'}}>
                              <i>(read-only status threshold)</i>
                            </span>
                            :
                          </div>
                        </Col>
                        <Col xs={12} sm={4}>
                          <Form.Group>
                            <Form.Label>All Currencies</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-vip-deposit-minimum"
                              type="number"
                              {...this.generateInputProps('vipDepositMinimum')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col xs={12} style={{textAlign: 'left', paddingBottom: 15}}>
                          <div>
                            <strong>
                              <i>First Deposit Bonus</i>{' '}
                            </strong>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Bonus Percentage on top of the deposit (%)</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-vip-ftd-bonus-percentage"
                              type="number"
                              {...this.generateInputProps('ftdBonusPercentageVIP')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in EUR *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-vip-ftd-bonus-min-deposit-eur"
                              {...this.generateMinDepositProps(currencies.EUR, 'ftdBonusMinimumDepositsVIP')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in USD *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-vip-ftd-bonus-min-deposit-usd"
                              {...this.generateMinDepositProps(currencies.USD, 'ftdBonusMinimumDepositsVIP')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in GBP *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-vip-ftd-bonus-min-deposit-gbp"
                              {...this.generateMinDepositProps(currencies.GBP, 'ftdBonusMinimumDepositsVIP')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>


                      <Row className="mb-3">
                        <Col xs={12} style={{textAlign: 'left', paddingBottom: 15}}>
                          <div>
                            <strong>
                              <i>Re-Deposit Bonus</i>{' '}
                            </strong>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Bonus Percentage on top of the deposit (%)</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-vip-redeposit-bonus-percentage"
                              type="number"
                              {...this.generateInputProps('redepositBonusPercentageVIP')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in EUR *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-vip-redeposit-bonus-min-deposit-eur"
                              {...this.generateMinDepositProps(currencies.EUR, 'redepositBonusMinimumDepositsVIP')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in USD *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-vip-redeposit-bonus-min-deposit-usd"
                              {...this.generateMinDepositProps(currencies.USD, 'redepositBonusMinimumDepositsVIP')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in GBP *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-vip-redeposit-bonus-min-deposit-gbp"
                              {...this.generateMinDepositProps(currencies.GBP, 'redepositBonusMinimumDepositsVIP')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </Col>
              </Row>

              {/*  RAW Account */}
              <Row className='mb-3'>
                <Col xs={12}>
                  <div
                    className={style.bonusSettingIconBar}
                    onClick={() => this.setState({showRAWBonusSettings: !showRAWBonusSettings})}
                    id='t-advance-settings-icon-bar'
                  >
                    <Col xs={12}>
                      <strong style={{fontSize: 17}}>
                        RAW Accounts Settings
                      </strong>
                      <FontAwesomeIcon
                        icon={`angle-double-${showRAWBonusSettings ? 'up' : 'down'}`}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Collapse in={showRAWBonusSettings}>
                    <div id='t-advance-filters'>
                      <Row className='mb-3'>
                        <Col xs={12} style={{textAlign: 'left', paddingBottom: 15}}>
                          <div>
                            <strong>
                              <i>Minimum Deposit</i>{' '}
                            </strong>
                            <span style={{color: 'grey'}}>
                              <i>(read-only status threshold)</i>
                            </span>
                            :
                          </div>
                        </Col>
                        <Col xs={12} sm={4}>
                          <Form.Group>
                            <Form.Label>All Currencies</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-raw-deposit-minimum"
                              type="number"
                              {...this.generateInputProps('rawDepositMinimum')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col xs={12} style={{textAlign: 'left', paddingBottom: 15}}>
                          <div>
                            <strong>
                              <i>First Deposit Bonus</i>{' '}
                            </strong>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Bonus Percentage on top of the deposit (%)</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-raw-ftd-bonus-percentage"
                              type="number"
                              {...this.generateInputProps('ftdBonusPercentageRAW')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in EUR *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-raw-ftd-bonus-min-deposit-eur"
                              {...this.generateMinDepositProps(currencies.EUR, 'ftdBonusMinimumDepositsRAW')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in USD *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-raw-ftd-bonus-min-deposit-usd"
                              {...this.generateMinDepositProps(currencies.USD, 'ftdBonusMinimumDepositsRAW')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in GBP *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-raw-ftd-bonus-min-deposit-gbp"
                              {...this.generateMinDepositProps(currencies.GBP, 'ftdBonusMinimumDepositsRAW')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>


                      <Row className="mb-3">
                        <Col xs={12} style={{textAlign: 'left', paddingBottom: 15}}>
                          <div>
                            <strong>
                              <i>Re-Deposit Bonus</i>{' '}
                            </strong>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Bonus Percentage on top of the deposit (%)</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-raw-redeposit-bonus-percentage"
                              type="number"
                              {...this.generateInputProps('redepositBonusPercentageRAW')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in EUR *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-raw-redeposit-bonus-min-deposit-eur"
                              {...this.generateMinDepositProps(currencies.EUR, 'redepositBonusMinimumDepositsRAW')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in USD *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-raw-redeposit-bonus-min-deposit-usd"
                              {...this.generateMinDepositProps(currencies.USD, 'redepositBonusMinimumDepositsRAW')}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={3}>
                          <Form.Group>
                            <Form.Label>Minimum Deposit in GBP *</Form.Label>
                            <Form.Control
                              id="t-campaigns-form-raw-redeposit-bonus-min-deposit-gbp"
                              {...this.generateMinDepositProps(currencies.GBP, 'redepositBonusMinimumDepositsRAW')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </Col>
              </Row>
            </div>
          </form>
        </Col>
      </Row>
    )
  }
}

export default compose(
  provideProps((state) => ({
    config: state.config
  }))
)(CampaignForm)
